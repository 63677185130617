
/**
 * @name: special
 * @author: Gzm
 * @date: 2023-07-24 09:21
 * @description：特价活动
 * @update: 2023-07-24 09:21
 */
import {Vue, Component} from "vue-property-decorator"
import {INotice, INoticeParam} from "@/apis/notice/types"
import {ICrudOption} from "@/types/m-ui-crud"

import {IPurchaseOrder,} from "@/apis/purchase/types";
import {
  popularityGoodsAddApi,
  popularityGoodsApi,
  popularityGoodsExportApi,
  popularityGoodsQueryApi,
  popularityGoodsRemoveApi,
  popularityGoodsSelectedApi,
  specialOfferBatchRemoveApi, specialOfferGoodsAddApi,
  specialOfferGoodsApi, specialOfferGoodsExportApi,
  specialOfferGoodsQueryApi,
  specialOfferGoodsSelectedApi, specialOfferModifyApi
} from "@/apis/marketing";
import {exportFile} from "@/utils/common";


@Component({})
export default class PopularityPage extends Vue {
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格数据
  tableData: INotice[] = []
  // 表格数据
  tableTotal: number = 0
  // 查询参数
  queryForm: INoticeParam = {
    page: 1,
    limit: 10
  }
  goodsDialog = false
  activityDialog = false
  titleList = ['商品库', '已选商品']
  goodsWarehouse = [] as any
  fullReduceSelected = [] as any
  fullReduceSelectedIds = [] as any
  // 表单数据
  modelForm = {
    activityPrice:null
  } as any

  // 配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "",
        type: "selection",
        width: 50,
        align: "center",
        selectable: this.rowSelectble,
        viewHide: true
      },
      {
        label: "商品ID",
        prop: "goodsId",
        align: "left",
        width: 180,
        editHide: true,
        addHide: true,
      },
      {
        label: "商品",
        prop: "goodsName",
        search: true,
        hide: true,
        placeholder: "输入ID/名称模糊搜索"
      },
      {
        label: "商品名称",
        prop: "goodsName",
        align: "center",
        viewHide: true,
      },
      {
        label: "商品属性",
        prop: "goodsTypes",
        align: "center",
        type: "switch",
        search: true,
        viewHide: true,
        dicData: [
          {
            label: "单品",
            value: 1
          },
          {
            label: "套餐",
            value: 2
          },
        ]
      },
      {
        label: "商品售价(元/份)",
        prop: "goodsPrice",
        align: "center",
      },
      {
        label: "商品活动价(点击可修改)",
        prop: "activityPrice",
        align: "center",
        slot: true
      },
      {
        label: "商品状态",
        prop: "status",
        addHide: true,
        editHide: true,
        width: 150,
        align: 'center',
        search: true,
        type: "switch",
        viewHide: true,
        dicData: [
          {
            label: "上架",
            value: 1
          },
          {
            label: "下架",
            value: 2
          },
        ]
      },
      {
        label: "操作",
        slot: true,
        prop: "btn",
        width: "200",
        align: "center"
      }
    ]
  }
  selectRow: any = []

  get selectIds() {
    return this.selectRow.map((item: any) => item.id)
  }


  getList() {
    this.tableLoading = true
    specialOfferGoodsQueryApi(this.queryForm).then(e => {
      this.tableData = e.list;
      this.tableTotal = e.total;
      this.tableLoading = false
    })
  }

  rowSelectble(row: any, index: number) {
    return true
  }


  openAdd() {
    this.goodsDialog = true
    this.goodsWarehouse = []
    this.fullReduceSelected = []
    this.fullReduceSelectedIds = []
    specialOfferGoodsSelectedApi().then(e => {
      this.fullReduceSelected = e.map((item: any) => {
        this.fullReduceSelectedIds.push(item.goodsId)
        return {
          label: item.goodsName,
          key: item.goodsId
        }
      })
      specialOfferGoodsApi().then(e => {
        this.goodsWarehouse = e.map((item: any) => {
          return {
            label: item.goodsName,
            key: item.goodsId
          }
        })
        this.goodsWarehouse = [...this.goodsWarehouse, ...this.fullReduceSelected]
      })
    })

  }

  /**
   * 删除采购订单
   * @param row
   */
  handleRemove(row: any) {
    this.$confirm('是否确认删除活动商品?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      specialOfferBatchRemoveApi([row.id]).then(e => {
        if (e) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getList();
        }
      })
    }).catch(() => {
    });
  }

  /**
   * 打开活动设置
   */
  handleActivityOpen(row:any){
    this.modelForm.activityPrice = row.activityPrice
    this.activityDialog = true
    this.modelForm.id = row.id
  }


  /**
   * 批量新增修改确认
   */
  handleRepealAffirm() {
    if (this.fullReduceSelectedIds.length == 0){
      return this.$message.error('请选择数据！')
    }
    specialOfferGoodsAddApi({
      ids: this.fullReduceSelectedIds
    }).then(e => {
      this.$message.success('操作成功！')
      this.goodsDialog = false
      this.getList()
    })
  }

  handleActivityAffirm(){
    if (!/^\d\.([1-9]{1,2}|[0-9][1-9])$|^[1-9]\d{0,5}(\.\d{1,2}){0,1}$|^100(\.0{1,2}){0,1}$/.test(this.modelForm.activityPrice)){
      return this.$message.error('数据类型正数，不可为0，可以为2位小数，最大数值999999.99!')
    }
    specialOfferModifyApi(this.modelForm).then(e => {
      if (e){
        this.$message.success('操作成功！')
        this.activityDialog = false
        this.getList()
      }
    })


  }

  /**
   * 导出excel
   */
  exportExcel() {
    specialOfferGoodsExportApi(this.queryForm).then(e => {
      exportFile(e, "特价活动.xlsx")
    })
  }

  /**
   * 批量提交采购订单
   * @param row
   */
  handleSubmitList() {
    if (this.selectIds.length == 0) {
      return this.$message.error("请选择数据")
    }
    this.$confirm('是否确认批量移除活动商品?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      specialOfferBatchRemoveApi(this.selectIds).then(e => {
        if (e) {
          this.$message({
            type: 'success',
            message: '提交成功!'
          });
          this.getList();
        }
      })
    }).catch(() => {
    });

  }


  created() {
    this.getList()
  }
}
